import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Petit bolet amb el capell en forma de campana de 0,5 a 2,5 cm de diàmetre, de color marró grisenc i estriat. Les làmines són adnades o sinuades de color blanquinós i el peu és llarg, fistulós i de color semblant al capell que quan es talla apareix un làtex abundant i blanc. Les espores són blanques en massa, el·lipsoïdals, de 10-14 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      